import { makeAutoObservable, runInAction } from "mobx";
import updateContactJourney from "../api/updateContact/updateContactJourney";
import Constants from "../models/constants";
import {
    GetConfigAttributeResponse,
    GetaccesstokenvordelResponse,
    GetUserDetailsResponse,
  
    updateDetailsResponse,
    UserAccountDetailsRequest,
    updateMarketingConsentsResponse,
    MarketingConsentRequest,
    updateContactInterceptAddSkipResponse
  } from "../models/UpdateContactModels/UpdateContactModel"

import { store } from "./store";

export default class UpdateContactStore {
  //  title = "Hello from Mobx!";

  editMode = false;
  loading = false;
  loadingInitial = false;

getUserDetailsResp :GetUserDetailsResponse | undefined = undefined;
getUpdateDetailsResp : updateDetailsResponse |undefined = undefined;
getUserDetailsError: string = "";
getMarketingConsentResp: updateMarketingConsentsResponse | undefined=undefined;
getUpdateContactInterceptAddSkipResp: updateContactInterceptAddSkipResponse | undefined=undefined;
getIsDSPUser : any;
//   getCurrentUserNameResponse: CurrentUserNameResponse | undefined = undefined;

//   getProfileResponse: UserNameResponse | undefined = undefined;

//   getValidateProfileApiResponse: ValidateProfileApiResponse | undefined =
//     undefined;

//   getClientCharacteristicApiResponse:
//     | ClientCharacteristicApiResponse
//     | undefined = undefined;

//   getTriggerKciResponse: TriggerKCIResponse | undefined = undefined;
//   sendVerificationMailResponse: SendVerificationMailResponse | undefined = undefined;

//   getUpdatePasswordApiResponse: UpdatePasswordApiResponse | undefined =
//     undefined;
    
//   getProfilesError: string = "";

//   validateProfilesError: string = "";

//   validateClientCharacteristicApiError: string = "";

//   triggerKciError: string = "";
//   sendVerificationMailError: string = "";

//   updatePasswordApiError: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  // Merge Recovery Store Methods

  //2

  getUserDetails = async () => {
    runInAction(() => {
      this.getUserDetailsResp  = undefined;
       
      });
      const data = await  updateContactJourney.getUserDetails()
         runInAction(() => {
          this.getUserDetailsResp = data;
       
         });
      // updateContactJourney
      // .getUserDetails()
      // .then((m) => {
      //   runInAction(() => {
      //      this.getUserDetailsResp = m;
      //     console.table("response from store:"+JSON.stringify(m));
      //   });
      //   store.commonStore.setAPIError("GETPROFILES", "");
      // })
  }
  updateUserDetails = async (requestObject:UserAccountDetailsRequest) => {
    runInAction(() => {
      this.getUpdateDetailsResp  = undefined;
      this.getUserDetailsError = "";
      });
         updateContactJourney
         .updateUserDetails(requestObject)
         .then((m) => {
           runInAction(() => {
             
             this.getUpdateDetailsResp = m;
           });
          
         store.commonStore.setAPIError("UPDATEUSERDETAILS", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.getUserDetailsError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError("UPDATEUSERDETAILS", Constants["APIERRORMESSAGE"]);
        console.log(err);
      });
      
  }
  
  updateMarketingConsents = async (updateMarketingConsentRequest:MarketingConsentRequest) => {
    runInAction(() => {
      this.getMarketingConsentResp  = undefined;
      this.getUserDetailsError = "";
      });
         updateContactJourney
         .updateMarketingConsents(updateMarketingConsentRequest)
         .then((m) => {
           runInAction(() => {
             
             this.getMarketingConsentResp = m;
           });
          
         store.commonStore.setAPIError("UPDATEUSERDETAILS", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.getUserDetailsError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError("UPDATEUSERDETAILS", Constants["APIERRORMESSAGE"]);
        console.log(err);
      });


}
UpdateContactInterceptAddSkip = async () => {
  runInAction(() => {
    this.getUpdateContactInterceptAddSkipResp  = undefined;
    this.getUserDetailsError = "";
    });
       updateContactJourney
       .UpdateContactInterceptAddSkip()
       .then((m) => {
         runInAction(() => {
           
           this.getUpdateContactInterceptAddSkipResp = m;
         });
        
       store.commonStore.setAPIError("UPDATEUSERDETAILS", "");
    })
    .catch((err) => {
      runInAction(() => {
        this.getUserDetailsError = Constants["APIERRORMESSAGE"];
      });
      store.commonStore.setAPIError("UPDATEUSERDETAILS", Constants["APIERRORMESSAGE"]);
      console.log(err);
    });


}
 

isDSPEnabledUser = async () => {
  runInAction(() => {
    this.getIsDSPUser = true;     
    });
  // updateContactJourney.isDSPEnabledUser().then((value) => {
  //       runInAction(() => {           
  //         this.getIsDSPUser = value != null ? value.IsDSPUser : false;
  //     });
  //   });
  }
}